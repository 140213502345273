<template>
  <el-dialog title="分配权限"
    :visible.sync="isShow"
    :before-close="closeDialog"
    @close="closeDialog"
    @opened="openDialog"
    width="30%"
    :close-on-click-modal="false">
    <el-tree :data="data"
      ref="rTree"
      :props="treeProps"
      show-checkbox
      :default-checked-keys="checkedKeys"
      node-key="id"
      default-expand-all></el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="updateRights">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'right_dialog',
  props: [
    'data',
    'isShow',
    'checkedKeys'
  ],
  data () {
    return {
      treeProps: {
        label: 'name',
        children: 'child_list'
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
      this.$refs.rTree.setCheckedKeys([])
    },
    updateRights () {
      const rIds = [...this.$refs.rTree.getHalfCheckedKeys(), ...this.$refs.rTree.getCheckedKeys()]
      this.$emit('updateRights', rIds)
    },
    openDialog () {
      this.$refs.rTree.setCheckedKeys(this.checkedKeys)
    }
  }
}
</script>

<style>
</style>
