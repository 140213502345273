<template>
  <el-dialog :title="title" :visible.sync="addVisible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="addFormRules" ref="addFormRef" label-width="80px">
      <el-form-item label="用户名" prop="user_name">
        <el-input :disabled="id>0" v-model="formData.user_name"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nick_name">
        <el-input v-model="formData.nick_name"></el-input>
      </el-form-item>
      <el-form-item v-if="id==0" label="密码" prop="password">
        <el-input v-model="formData.password" type="password"></el-input>
      </el-form-item>
      <el-form-item v-if="id==0" label="确认密码" prop="password_confirm">
        <el-input v-model="formData.password_confirm" type="password"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio v-model="formData.sex" :label="1">男</el-radio>
        <el-radio v-model="formData.sex" :label="2">女</el-radio>
        <el-radio v-model="formData.sex" :label="0">未知</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'member_add',
  props: [
    'addVisible', 'id'
  ],
  data () {
    const reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/
    const validatePwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error('密码应是6-12位数字、字母或字符！'))
      } else {
        callback()
      }
    }
    const validateComfirmPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error('密码应是6-12位数字、字母或字符！'))
      } else if (this.formData.password !== value) {
        callback(new Error('确认密码与新密码不一致！'))
      } else {
        callback()
      }
    }
    return {
      title: '添加会员',
      formData: {
        user_name: '',
        nick_name: '',
        password: '',
        password_confirm: '',
        sex: 1
      },
      addFormRules: {
        user_name: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        nick_name: [
          { required: true, message: '昵称不能为空', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ],
        password_confirm: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validateComfirmPwd, trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.addFormRef.resetFields()
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('addMember', this.formData)
        } else {
          this.$emit('updateMemeber', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑会员'
        this.getMemberInfo()
      }
    },
    async getMemberInfo () {
      const res = await this.$http.get('/admin/member/info', { id: this.id })
      if (res.code === 0) {
        this.formData = res.data
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style>
</style>
