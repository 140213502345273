<template>
  <div>
    <div :class="containerClass">
      <!-- 内容 -->
      <div
        class="image-container"
        v-for="Item in imageList"
        :key="Item.id"
        @mouseenter="hoverImage(Item.id, true)"
        @mouseleave="hoverImage(Item.id, false)"
        @click="showEditDialog(Item.id)"
      >
        <a href="#">
          <el-image
            :src="Item.path + '?imageView2/2/w/640'"
            @click="showEditDialog(Item.id)"
          ></el-image>
        </a>
        <div class="inhead" :style="{ opacity: hoveredId==Item.id ? 1 : 0 }">
          <span>{{Item.title}}</span>
        </div>
      </div>
    </div>
    <p ref="divwidth" style="opacity:0;">当前窗口宽度: {{ windowWidth }}px</p>
  </div>
</template>

<script>
export default {
  name: 'image_index_list',
  props: [
    'imageList', 'columnCount'
  ],
  data () {
    return {
      windowWidth: '',
      resizeObserver: null,
      hoveredId: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.windowWidth = this.$refs.divwidth.offsetWidth
      this.resizeObserver = new ResizeObserver(this.handleResize)
      this.resizeObserver.observe(this.$refs.divwidth)
    })
  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  computed: {
    containerClass () {
      switch (this.columnCount) {
        case 6:
          return 'container-6'
        case 5:
          return 'container-5'
        case 4:
          return 'container-4'
        case 3:
          return 'container-3'
        case 2:
          return 'container-2'
        case 1:
          return 'container-1'
      }

      if (this.windowWidth > 1280) {
        return 'container-6'
      } else if (this.windowWidth > 930 && this.windowWidth <= 1280) {
        return 'container-4'
      } else if (this.windowWidth > 700 && this.windowWidth <= 930) {
        return 'container-2'
      } else {
        return 'container-1'
      }
    }
  },
  methods: {
    hoverImage (id, flag) {
      if (flag) {
        this.hoveredId = id
      } else {
        this.hoveredId = 0
      }
    },
    showEditDialog (id) {
      this.$router.push({
        path: '/image/add',
        query: {
          id: id
        }
      })
    },
    handleResize () {
      this.windowWidth = this.$refs.divwidth.offsetWidth
    }
  }
}
</script>

<style>
.show-span {
  display: inline-block;
  /* 其他样式 */
}

.container-6 {
  /* display:flex; */
  flex-wrap: wrap;
  margin: 5px;
  column-count: 6;
  column-gap: 10px;
  .image-container {
  margin-bottom: 10px;
  position: relative;
}
}

.container-5 {
  /* display:flex; */
  flex-wrap: wrap;
  margin: 6px;
  column-count: 5;
  column-gap: 12px;

  .image-container {
  margin-bottom: 12px;
  position: relative;
}
}

.container-4 {
  /* display:flex; */
  flex-wrap: wrap;
  margin: 8px;
  column-count: 4;
  column-gap: 16px;
  .image-container {
  margin-bottom: 16px;
  position: relative;
}
}

.container-3 {
  /* display:flex; */
  flex-wrap: wrap;
  margin: 10px;
  column-count: 3;
  column-gap: 20px;
  .image-container {
  margin-bottom: 20px;
  position: relative;
}
}

.container-2 {
  /* display:flex; */
  flex-wrap: wrap;
  margin: 7px;
  column-count: 2;
  column-gap: 14px;
  .image-container {
  margin-bottom: 14px;
  position: relative;
}
}

.container-1 {
  /* 小屏幕样式 */
  .image-container {
  margin-bottom: 15px;
  position: relative;
}
}

/* .image-container {
  margin-bottom: 20px;
  position: relative;
} */

.el-image {
  display: block !important;
}

.inhead{
    transition: all .25s;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 32px);
    padding: 16px;
    background: linear-gradient(180deg,rgba(25,27,38,.56),rgba(25,27,38,0));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.inhead span{
  color: hsla(0,0%,100%,.8);
}
</style>
