import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Quill from 'quill'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'

import http from './utils/http.js'
Vue.prototype.$http = http

Vue.config.productionTip = false

Vue.filter('dateTimeFormat', function (value) {
  const dt = new Date(value * 1000)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.filter('dateFormat', function (value) {
  const dt = new Date(value)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  return `${y}-${m}-${d}`
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const BlockEmbed = Quill.import('blots/block/embed')
class AudioBlot extends BlockEmbed {
  static create (value) {
    const node = super.create()
    node.setAttribute('src', value.url)
    node.setAttribute('controls', true)
    node.setAttribute('controlsList', 'nodownload')
    node.setAttribute('id', 'voice')
    return node
  }

  static value (domNode) {
    const value = {
      url: '',
      name: ''
    }
    if (domNode.getAttribute('src')) {
      value.url = domNode.getAttribute('src')
      value.name = domNode.getAttribute('name')
    }
    return value
  }
}
AudioBlot.blotName = 'audio'
AudioBlot.tagName = 'audio'
Quill.register(AudioBlot)
