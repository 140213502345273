import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import MemberList from '../components/member/MemberList.vue'
import AdminList from '../components/admin/AdminList.vue'
import AdminCategoryList from '../components/adminCategory/AdminCategoryList.vue'
import ImgCategoryList from '../components/imgCategory/ImgCategoryList.vue'
import AlbumList from '../components/album/AlbumList.vue'
import AlbumAdd from '../components/album/AlbumAdd.vue'
import RightList from '../components/right/RightList.vue'
import RoleList from '../components/role/RoleList.vue'
import ConfigList from '../components/config/ConfigList.vue'
import ImageList from '../components/image/ImageList.vue'
import ImageAdd from '../components/image/ImageAdd.vue'
import TestUeditor from '../components/test/ueditor.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/image/list',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/member/list', component: MemberList },
      { path: '/admin/list', component: AdminList },
      { path: '/admincategory/list', component: AdminCategoryList },
      { path: '/imgcategory/list', component: ImgCategoryList },
      { path: '/album/list', component: AlbumList },
      { path: '/album/add', component: AlbumAdd },
      { path: '/right/list', component: RightList },
      { path: '/role/list', component: RoleList },
      { path: '/config/list', component: ConfigList },
      { path: '/image/list', component: ImageList },
      { path: '/image/add', component: ImageAdd },
      { path: '/test/editor', component: TestUeditor }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes: routes
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const token = window.sessionStorage.getItem('token')
  if (!token) return next('/login')
  next()
})

export default router
