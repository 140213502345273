import axios from 'axios'
import server from '../config/server.js'

// 请求拦截，加头部token
axios.interceptors.request.use(config => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})

const http = {
  get (url, data) {
    return new Promise((resolve, reject) => {
      axios.get(server.url + url, {
        params: data
      }).then((response) => {
        if (response) {
          resolve(response.data)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

  post (url, data) {
    return new Promise((resolve, reject) => {
      axios.post(server.url + url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response) {
          resolve(response.data)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default http
