<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item><a @click="gotoIndex()">首页</a></el-breadcrumb-item>
    <el-breadcrumb-item>{{path}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{subPath}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'break_crumb',
  props: [
    'path', 'subPath'
  ],
  methods: {
    gotoIndex (path) {
      window.sessionStorage.setItem('menu_active_index', '')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
</style>
