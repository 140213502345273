<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="70%" @opened="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="80px">
      <el-form-item label="标题" prop="name">
        <el-input v-model="formData.name" style="width:30%"></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="key">
        <el-input v-model="formData.key" style="width:30%"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="formData.type" placeholder="请选择类型">
          <el-option label="富文本" :value="1"></el-option>
          <el-option label="简单文本" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" prop="value" style="width: 98%;">
        <el-input v-if="formData.type==2" v-model="formData.value" type="textarea" :rows="3"></el-input>
        <quill-editor v-else ref="myQuillEditor" v-model="formData.value" :options="editorOption" /></el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'config_add',
  props: [
    'visible', 'id'
  ],
  components: {
    'quill-editor': quillEditor
  },
  data () {
    return {
      title: '添加配置',
      formData: {
        name: '',
        key: '',
        type: 1,
        value: ''
      },
      formRules: {
        name: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        key: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ]
      },
      editorOption: {
        placeholder: '编辑配置内容'
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑配置'
        this.getConfigInfo(this.id)
      }
    },
    async getConfigInfo (id) {
      const res = await this.$http.get('/admin/config/info/' + id)
      if (res.code === 0) {
        this.formData = {
          name: res.data.name,
          key: res.data.key,
          type: res.data.type,
          value: res.data.value
        }
      }
    }
  }
}
</script>

<style>
</style>
