<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='用户管理' subPath='分类列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" @click="showAddDialog">添加分类</el-button>
        </el-col>
      </el-row>
      <el-table :data="list" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{scope.row.create_time | dateTimeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="290px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <admin-category-add :visible="addDialogAvaible"
      :id="currentId"
      @add="addCategory"
      @update="updateCategory"
      @closeDialog="closeAddDialog"></admin-category-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import AdminCategoryAdd from './AdminCategoryAdd.vue'
export default {
  name: 'category_list',
  components: {
    'bread-crumb': BreadCrumb,
    'admin-category-add': AdminCategoryAdd
  },
  created () {
    this.getList()
  },
  data () {
    return {
      currentId: 0,
      list: [],
      addDialogAvaible: false
    }
  },
  methods: {
    async getList () {
      const res = await this.$http.get('/admin/admincategory/list')
      if (res.code === 0) {
        this.list = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async addCategory (data) {
      const res = await this.$http.post('/admin/admincategory/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateCategory (id, data) {
      const res = await this.$http.post('/admin/admincategory/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/admincategory/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.el-image {
  width: 70px;
}
</style>
