<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='配置列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" @click="showAddDialog">添加配置</el-button>
        </el-col>
      </el-row>
      <el-table :data="configList" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
        <el-table-column prop="name" label="标题" align="center"></el-table-column>
        <el-table-column prop="key" label="名称" align="center"></el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{scope.row.create_time | dateTimeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="290px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="param.count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <config-add :id="currentId"
      :visible="addDialogAvaible"
      @closeDialog="closeDialog"
      @update="updateConfig"
      @add="addConfig"></config-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import ConfigAdd from './ConfigAdd.vue'
export default {
  name: 'config_list',
  components: {
    'bread-crumb': BreadCrumb,
    'config-add': ConfigAdd
  },
  created () {
    this.getConfigList()
  },
  data () {
    return {
      currentId: 0,
      configList: [],
      total: 0,
      pageTotal: 1,
      param: {
        keywords: '',
        page: 1,
        count: 10
      },
      addDialogAvaible: false
    }
  },
  methods: {
    async getConfigList () {
      const res = await this.$http.get('/admin/config/list', this.param)
      if (res.code === 0) {
        this.configList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getConfigList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getConfigList()
    },
    async addConfig (data) {
      const res = await this.$http.post('/admin/config/add', data)
      if (res.code === 0) {
        this.closeDialog()
        this.getConfigList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateConfig (id, data) {
      const res = await this.$http.post('/admin/config/update/' + id, data)
      if (res.code === 0) {
        this.closeDialog()
        this.getConfigList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/config/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getConfigList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>
</style>
