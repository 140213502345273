<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='专辑管理' :subPath="title"></bread-crumb>
    <el-form :model="formData" :rules="addFormRules" ref="addFormRef" label-width="100px" class="form_body">
      <el-form-item label="标题" prop="title">
        <el-input :disabled="disabled" v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="introduce">
        <el-input :disabled="disabled" type="textarea" autosize v-model="formData.introduce"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select :disabled="disabled" v-model="formData.status" placeholder="请选择状态" filterable>
          <el-option label="仅自己可见" :value="1"></el-option>
          <el-option label="部门用户可见" :value="2"></el-option>
          <el-option label="后台用户可见" :value="3"></el-option>
          <el-option label="所有人可见" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <div class="upload-list" v-if="picList.length > 0">
          <div class="upload" v-for="(item, index) in picList" :key="index">
            <img :src="item.picUrl + '?imageView2/2/w/250'" class="avatar" @click="copyLink(item.picUrl)">
            <div class="img-button" v-if="item.picUrl">
              <el-button v-if="item.height && item.height > 720 && item.filesize && item.filesize < 20480" type="primary"
                @click="downloadImage(1, item.picUrl)">720p下载</el-button>
              <el-button v-if="item.height && item.height > 1080 && item.filesize && item.filesize < 20480" type="primary"
                @click="downloadImage(2, item.picUrl)">1080p下载</el-button>
              <el-button v-if="item.height" type="primary" @click="downloadImage(3, item.picUrl)">{{ item.height
              }}p原画下载</el-button>
            </div>
            <a class="cannel">
              <svg @click="handlePicClose(item)" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                data-v-ea893728="">
                <path fill="currentColor"
                  d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z">
                </path>
              </svg>
            </a>
          </div>
        </div>
        <template>
          <el-upload class="avatar-uploader" :before-upload="beforeImgUpload" :drag="true" action="/proxy/upload_img" :multiple="true" name="img" :http-request="uploadFile"
            :show-file-list="false" :on-success="handleSuccess">
            <img  src="../../assets/upload.png" />
          </el-upload>
          <el-progress v-show="showProgress" :percentage="progress"></el-progress>
          <span v-show="showProgress">{{currentString}}  (99%是正常的~)</span>
        </template>
      </el-form-item>
      <el-collapse v-if="id == 0">
        <el-collapse-item title="图片公共信息" name="1">
          <el-form-item label="拍摄者/作者" prop="author">
        <el-input :disabled="disabled" v-model="formData.author"></el-input>
      </el-form-item>
      <el-form-item label="拍摄地点" prop="shooting_place">
        <el-input :disabled="disabled" v-model="formData.shooting_place"></el-input>
      </el-form-item>
      <el-form-item :disabled="disabled" label="拍摄时间" prop="shooting_time">
        <el-date-picker
          :disabled="disabled"
          v-model="formData.shooting_time"
          type="datetime"
          value-format="timestamp"
          placeholder="拍摄时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="公共标签" prop="tag">
        <div class="tag">
          <el-tag v-for="tag in tagList" :key="tag.name" closable :type="tag.type" @close="handleClose(tag)">
            {{ tag.name }}
          </el-tag>
        </div>
        <el-autocomplete :disabled="disabled" v-model="tempTagInput" :fetch-suggestions="getTag" placeholder="请输入标签" @select="handleSelect">
          <el-button slot="append" @click="addTag" icon="el-icon-circle-check"></el-button>
        </el-autocomplete>
      </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <div style="text-align: center;">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button v-show="!disabled" type="primary" @click="addConfirm">确 定</el-button>
        <el-button v-show="!disabled" v-if="id > 0" type="danger" @click="delConfirm">删除</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import axios from 'axios'
export default {
  name: 'album_add',
  components: {
    'bread-crumb': BreadCrumb
  },
  mounted () {
    this.id = this.$route.query.id
    if (this.id > 0) {
      this.title = '专辑编辑'
      this.getInfo(this.id)
    } else {
      this.title = '专辑添加'
    }
  },
  data () {
    return {
      id: 0,
      progress: 0,
      showProgress: false,
      disabled: false,
      is_owner: false,
      title: '添加专辑',
      formData: {
        title: '',
        introduce: '',
        status: 1,
        shooting_place: '',
        shooting_time: 0,
        author: ''
      },
      tagList: [],
      tempTagInput: '',
      picList: [],
      addFormRules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ]
      },
      waitingArray: ['上传中', '加载中', '稍等', '有点卡', '没死机', '网挺慢啊', '看看远处放松一下', '蛙趣!还没传完?!!!', '图片是有多大啊!!!', '我愿称呼你的网络为最强!!!'],
      currentIndex: 0,
      waitingIntervalId: null
    }
  },
  watch: {
    showProgress (newvalue) {
      if (newvalue) {
        this.waitingIntervalId = setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.waitingArray.length
        }, 3000)
      } else {
        clearInterval(this.waitingIntervalId)
        this.currentIndex = 0
      }
    }
  },
  computed: {
    currentString () {
      return this.waitingArray[this.currentIndex]
    }
  },
  methods: {
    copyLink (picurl) {
      navigator.clipboard.writeText(picurl)
        .then(() => {
          this.$message.success('已复制到剪贴板')
        })
        .catch((error) => {
          this.$message.error('复制文本到剪贴板失败:', error)
        })
    },
    beforeImgUpload (file) {
    /* // 图片格式
      const isJPG = file.type === 'image/jpg' || file.type === 'image/png'
      // 图片大小
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能为jpg或png格式')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过2MB')
      } */
      const _this = this
      const isSize = new Promise(function (resolve, reject) {
        const img = new Image()
        const _URL = window.URL || window.webkitURl
        img.onload = function () {
          file.width = img.width// 图片宽度
          file.height = img.height// 图片高度
          const valid = img.width > 1000 || img.height > 1000// 上传图片尺寸判定
          valid ? resolve() : reject(new Error('error'))
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => { return file },
        () => {
          _this.$message.error('上传图片宽度或高度至少大于1000px')
          return Promise.reject(new Error('error'))
        }
      )
      return /* isJPG && isLt2M && */ isSize
    },
    uploadFile (param) {
      const formData = new FormData()
      formData.append('img', param.file)
      formData.append('PHP_SESSION_UPLOAD_PROGRESS', 'file1')
      // 发起请求
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          token: window.sessionStorage.getItem('token')
        },
        // 上传地址，因为我这里的request请求是自己封装过的，所以就只需要填写接口后面的地址即可
        url: '/proxy/upload_img',
        data: formData,
        // 重点一：complete就是处理后的上传进度数值1-100
        onUploadProgress: progressEvent => {
          this.showProgress = true
          const complete = parseInt(
            ((progressEvent.loaded / progressEvent.total) * 100) | 0,
            10
          )
          this.progress = complete - 1
          // 重点二：onProgress()方法需要以上方接收的形参来调用
          // 这个方法有一个参数"percent"，给他进度值 complete 即可
          console.log(complete)
        }
      }).then(resp => {
        this.showProgress = false
        if (resp.data.code === 0) {
          this.picList.push({
            picUrl: resp.data.data.url,
            path: resp.data.data.path,
            filesize: resp.data.data.size,
            filetype: resp.data.data.ext,
            width: resp.data.data.width,
            height: resp.data.data.height
          })
          this.formData.icon = resp.data.data.path
        } else {
          this.$message.error(resp.data.msg)
        }
      })
    },
    handleClose (tag) {
      const item = this.tagList.indexOf(tag)
      this.tagList.splice(item, 1)
    },
    handlePicClose (pic) {
      const item = this.picList.indexOf(pic)
      this.picList.splice(item, 1)
    },
    async addTag () {
      if (this.tempTagInput === '') {
        return
      }
      const addData = { name: this.tempTagInput }
      const res = await this.$http.post('/admin/tag/add', addData)
      if (res.code === 0) {
        this.tagList.push({
          name: res.data.name,
          type: 'success',
          id: res.data.id
        })
        this.tempTagInput = ''
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSelect (item) {
      for (let i = 0; i < this.tagList.length; i++) {
        if (this.tagList[i].id === item.id) {
          this.tempTagInput = ''
          return
        }
      }
      this.tagList.push({
        id: item.id,
        name: item.value
      })
      this.tempTagInput = ''
    },
    async getTag (data, cb) {
      if (data === '') {
        return
      }
      const searchData = { name: data }
      const res = await this.$http.post('/admin/tag/search', searchData)
      if (res.code === 0) {
        cb(res.data)
      } else {
        this.$message.error(res.msg)
      }
    },
    closeDialog () {
      this.$refs.addFormRef.resetFields()
      this.imageUrl = ''
      this.$router.push('/album/list')
    },
    addConfirm () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.formData.tag_list = this.tagList
        this.formData.pic_list = this.picList
        if (this.id > 0) {
          this.updateAlbum(this.id, this.formData)
        } else {
          this.formData.shooting_time = this.formData.shooting_time / 1000
          this.addAlbum(this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑专辑'
        this.getInfo(this.id)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/album/info/' + id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          introduce: res.data.introduce,
          status: res.data.status
        }
        res.data.img_list != null ? this.picList = res.data.img_list : this.picList = []
        this.is_owner = res.data.is_owner
        this.disabled = !res.data.is_owner
      }
    },
    async delConfirm () {
      var data = {
        id: this.id
      }
      const res = await this.$http.post('/admin/album/delete/' + this.id, data)
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.closeDialog()
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.picList.push({
          picUrl: resp.data.url,
          path: resp.data.path,
          filesize: resp.data.size,
          filetype: resp.data.ext,
          width: resp.data.width,
          height: resp.data.height
        })
        this.formData.icon = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    },
    async addAlbum (data) {
      const res = await this.$http.post('/admin/album/add', data)
      if (res.code === 0) {
        this.closeDialog()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateAlbum (id, data) {
      const res = await this.$http.post('/admin/album/update/' + id, data)
      if (res.code === 0) {
        this.closeDialog()
      } else {
        this.$message.error(res.msg)
      }
    },
    downloadImage (option, url) {
      switch (option) {
        case 1:
          this.downloadfile(url + '?imageView2/2/h/720', 'img')
          break
        case 2:
          this.downloadfile(url + '?imageView2/2/h/1080', 'img')
          break
        case 3:
          this.downloadfile(url, 'img')
          break
      }
    },
    downloadfile (imgSrc, name) {
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imgSrc
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.download = name || 'photo'
          a.href = url
          a.click()
          a.remove()
          URL.revokeObjectURL(url)
        })
      }
    }
  }
}
</script>

<style scoped>
.form_body {
  background-color: #ffffff;
  padding: 20px 20px 30px 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.cannel {
  padding: 3px;
  width: 20px;
}

.upload-list {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.el-upload-dragger{
}

.upload {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.img-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  margin: 10px 10px;
}

.img-button button {
  width: 140px;
  margin-left: 10px;
}

.avatar {
  width: 250px;
  height: auto;
  display: inline-block;
}

.tag {
  margin-bottom: 20px;
}

.tag span {
  margin: 0 5px;
}

.el-collapse{
  margin: 0 50px;
  margin-bottom: 22px;
}
</style>
