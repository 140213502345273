<template>
  <vue-ueditor-wrap v-model="data" :config="myConfig"></vue-ueditor-wrap>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'test_ueditor',
  components: {
    'vue-ueditor-wrap': VueUeditorWrap
  },
  data () {
    return {
      data: 'hello',
      myConfig: {
        autoHeightEnabled: false,
        serverUrl: '/proxy/upload',
        imageActionUrl: 'http://www.gospeltimes.cn/proxy_upload/upload/img',
        imageUrlPrefix: 'http://localhost:8080/',
        fileActionUrl: 'http://www.gospeltimes.cn/proxy_upload/upload/img',
        imageAllowFiles: ['.png', '.jpg', '.jpeg', ',gif'],
        imageMaxSize: 20480000,
        fileAllowFiles: ['.pdf', 'doc', 'docx', 'zip', 'rar'],
        initialFrameHeight: 320,
        initialFrameWidth: '100%',
        UEDITOR_HOME_URL: '/ueditor/'
      }
    }
  }
}
</script>

<style scoped>
</style>
