<template>
  <div>Welcome</div>
</template>

<script>
export default {
  name: 'Welcome',
  created () {
    window.sessionStorage.setItem('menu_active_index', '')
  },
  mounted () {
    window.sessionStorage.setItem('menu_active_index', '')
  }
}
</script>

<style>
</style>
