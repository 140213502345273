<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='专辑列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row type="flex" style="display:flex;flex-wrap: wrap;">
        <el-col style="display: inline-block;width: 100px;margin: 5px 5px;">
          <el-button type="primary" @click="showAddDialog">添加专辑</el-button>
        </el-col>
        <el-col style="display: inline-block; width: 300px;margin: 5px 5px;">
          <el-input placeholder="请输入关键字" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="list" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
        <el-table-column prop="pic" label="图片" align="center" width="150px">
        <template slot-scope="scope">
          <el-image :src="scope.row.cover_path + '?imageView2/2/w/150'"></el-image>
        </template>
      </el-table-column>
        <el-table-column prop="title" label="标题" align="center"></el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateTimeFormat }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="290px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]" :page-size="param.count" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
export default {
  name: 'album_list',
  components: {
    'bread-crumb': BreadCrumb
  },
  created () {
    this.getList()
    this.getAdminCategoryList()
  },
  data () {
    return {
      currentId: 0,
      list: [],
      adminCategoryList: [],
      total: 0,
      pageTotal: 1,
      param: {
        admin_category_id: 0,
        keywords: '',
        page: 1,
        count: 20
      },
      addDialogAvaible: false
    }
  },
  methods: {
    async getList () {
      const res = await this.$http.get('/admin/album/list', this.param)
      if (res.code === 0) {
        this.list = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    async getAdminCategoryList () {
      const res = await this.$http.get('/admin/admincategory/select_list')
      if (res.code === 0) {
        this.adminCategoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getList()
    },
    showAddDialog () {
      this.$router.push({
        path: '/album/add',
        query: {
          id: 0
        }
      })
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.$router.push({
        path: '/album/add',
        query: {
          id: id
        }
      })
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getList()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/album/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => { })
    }
  }
}
</script>

<style scoped>
.el-image {
  width: 130px;
}
</style>
