<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像 -->
      <div class="avatar_box">
        <img src="../assets/pray.jpg" />
      </div>
      <!-- 登录表单 -->
      <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="name">
          <el-input v-model="loginForm.name" prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" prefix-icon="iconfont icon-3702mima"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="loginSubmit">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      loginForm: {
        name: '',
        password: ''
      },
      loginRules: {
        name: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    const token = window.sessionStorage.getItem('token')
    if (token !== null) {
      this.$router.push('/home')
    }
  },
  methods: {
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    loginSubmit () {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          var res = await this.$http.post('/admin/login', this.loginForm)
          if (res.code === 0) {
            this.$message.success('登录成功')
            window.sessionStorage.setItem('token', res.data.token)
            window.sessionStorage.setItem('publish', res.data.publish)
            this.$router.push('/home')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  }
}
</script>

<style scoped="scoped">
.login_container {
  background-color: #006fc4;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_box .avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #EEEEE;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #DDDDDD;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
}
.login_box .avatar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #EEEEEE;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
