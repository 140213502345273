<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='用户管理' subPath='会员列表'></bread-crumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入用户名或昵称" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="memberList" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="user_name" label="用户名" align="center"></el-table-column>
        <el-table-column prop="nick_name" label="昵称" align="center"></el-table-column>
        <el-table-column label="注册时间" align="center">
          <template slot-scope="scope">
            {{scope.row.create_time | dateTimeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="175px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-document" size="mini"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMember(scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="param.count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <member-add :id="id"
      :addVisible="addVisible"
      @closeDialog="closeDialog"
      @updateMemeber="updateMemberPost"
      @addMember="addMemberPost"></member-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import MemberAdd from './MemberAdd.vue'

export default {
  name: 'member_list',
  components: {
    'bread-crumb': BreadCrumb,
    'member-add': MemberAdd
  },
  created () {
    this.getMemberList()
  },
  data () {
    return {
      memberList: [],
      total: 0,
      pageTotal: 1,
      param: {
        keywords: '',
        page: 1,
        count: 10
      },
      addVisible: false,
      addForm: {
        name: ''
      },
      id: 0
    }
  },
  methods: {
    async getMemberList () {
      const res = await this.$http.get('/admin/member/list', this.param)
      if (res.code === 0) {
        this.memberList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getMemberList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getMemberList()
    },
    search () {
      this.param.page = 1
      this.getMemberList()
    },
    addMember () {
      this.openDialog()
    },
    showEditDialog (id) {
      this.id = id
      this.openDialog()
    },
    openDialog () {
      this.addVisible = true
    },
    closeDialog () {
      this.addVisible = false
      this.id = 0
    },
    async addMemberPost (memberData) {
      // 添加请求
      const res = await this.$http.post('/admin/member/add', memberData)
      if (res.code === 0) {
        this.$message.success('添加成功')
        this.closeDialog()
        // 重新获取列表
        this.param = {
          keywords: '',
          page: 1,
          count: 10
        }
        this.getMemberList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateMemberPost (memberId, upData) {
      const res = await this.$http.post('/admin/member/update/' + memberId, upData)
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.closeDialog()
        // 重新获取列表
        this.getMemberList()
      } else {
        this.$message.error(res.msg)
      }
    },
    deleteMember (memberId) {
      this.$confirm('是否真的要删除此用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteMemberPost(memberId)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {})
    },
    async deleteMemberPost (memberId) {
      const res = await this.$http.post('/admin/member/delete/' + memberId)
      if (res.code === 0) {
        this.getMemberList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
</style>
