<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='权限管理' subPath='角色列表'></bread-crumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" @click="showAddDialog">添加角色</el-button>
        </el-col>
      </el-row>
      <el-table :data="roleList" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row :class="['bdbottom', index1===0 ? 'bdtop' : '', 'vcenter']" v-for="(item1, index1) in scope.row.right" :key="item1.id">
              <el-col :span="5">
                <el-tag closable @close="removeRight(scope.row, item1.id)" type="danger">{{item1.name}}</el-tag>
                <i class="el-icon-caret-right"></i>
              </el-col>
              <el-col :span="19">
                <el-row :class="[index2===0 ? '' : 'bdtop', 'vcenter']" v-for="(item2, index2) in item1.child_list" :key="item2.id">
                  <el-col :span="6">
                    <el-tag closable @close="removeRight(scope.row, item2.id)" type="success">{{item2.name}}</el-tag>
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <el-col :span="18">
                    <el-tag closable @close="removeRight(scope.row, item3.id)" type="warning" class="right3" v-for="item3 in item2.child_list" :key="item3.id">
                      {{item3.name}}
                    </el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column prop="intro" label="描述" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="290px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRight(scope.row.id)">删除</el-button>
            <el-button type="warning" icon="el-icon-setting" size="mini" @click="showSetRightDialog(scope.row)">分配权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 分配权限对话框 -->
    <right-dialog :data="rightTree"
      :isShow="setRightDialogVisible"
      :checkedKeys="checkedKeys"
      @updateRights="updateRights"
      @closeDialog="closeSetRightDialog"></right-dialog>
    <!-- 添加角色对话框 -->
    <role-add :visible="addDialogAvaible"
      :id="roleId"
      @add="addRole"
      @update="updateRole"
      @close="closeAddDialog">
    </role-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import RightDialog from './RightDialog.vue'
import RoleAdd from './RoleAdd.vue'
export default {
  name: 'role_list',
  components: {
    'bread-crumb': BreadCrumb,
    'right-dialog': RightDialog,
    'role-add': RoleAdd
  },
  data () {
    return {
      roleList: [],
      rightTree: [],
      checkedKeys: [],
      roleId: 0,
      setRightDialogVisible: false,
      addDialogAvaible: false
    }
  },
  created () {
    this.getList()
    this.getRightTree()
  },
  methods: {
    async getList () {
      const res = await this.$http.get('/admin/role/list')
      if (res.code === 0) {
        this.roleList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async addRole (data) {
      const res = await this.$http.post('/admin/role/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateRole (id, data) {
      const res = await this.$http.post('/admin/role/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async getRightTree () {
      const res = await this.$http.get('/admin/right/tree')
      if (res.code === 0) {
        this.rightTree = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    removeRight (role, rightId) {
      this.$confirm('是否真的要删除此权限?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {})
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.roleId = 0
    },
    showEditDialog (id) {
      this.roleId = id
      this.showAddDialog()
    },
    deleteRight () {
    },
    showSetRightDialog (role) {
      this.checkedKeys = role.leaf_r_ids
      this.roleId = role.id
      this.setRightDialogVisible = true
    },
    closeSetRightDialog () {
      this.setRightDialogVisible = false
    },
    async updateRights (rIds) {
      const data = {
        role_id: this.roleId,
        right_ids: rIds
      }
      const res = await this.$http.post('/admin/role/right/update', data)
      if (res.code === 0) {
        this.closeSetRightDialog()
        this.$message.success('更新权限成功')
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.el-tag {
  margin-top: 7px;
  margin-bottom: 7px;
}

.right3 {
  margin-right: 7px;
}

.bdtop {
  border-top: 1px solid #EEEEEE;
}
.bdbottom {
  border-bottom: 1px solid #EEEEEE;
}

.vcenter {
  display: flex;
  align-items: center;
}
</style>
