<template>
  <el-dialog title="修改密码" :visible.sync="resetVisible" :before-close="closeDialog" @closed="closeDialog" width="30%" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="80px">
      <el-form-item label="原密码" prop="password_old">
        <el-input v-model="formData.password_old" type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password_new">
        <el-input v-model="formData.password_new" type="password"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="resetConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'password_reset',
  props: [
    'resetVisible'
  ],
  data () {
    const reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,25}$/
    const validatePwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error('密码应是6-25位数字、字母或字符！'))
      } else {
        callback()
      }
    }
    return {
      title: '添加会员',
      formData: {
        password_old: '',
        password_new: ''
      },
      formRules: {
        password_old: [
          { required: true, message: '原密码不能为空', trigger: 'blur' }
        ],
        password_new: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.$emit('closeResetDialog')
    },
    resetConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.$emit('passwordReset', this.formData)
      })
    }
  }
}
</script>

<style>
</style>
