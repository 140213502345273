<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='权限管理' subPath='权限列表'></bread-crumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" @click="showAddDialog">添加权限</el-button>
        </el-col>
      </el-row>
      <el-table :data="rightList" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column label="权限等级" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.level==1" type="danger">一级权限</el-tag>
            <el-tag v-else-if="scope.row.level==2" type="success">二级权限</el-tag>
            <el-tag v-else type="warning">三级权限</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="175px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRight(scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
export default {
  name: 'right_list',
  components: {
    'bread-crumb': BreadCrumb
  },
  data () {
    return {
      rightList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await this.$http.get('/admin/right/list')
      if (res.code === 0) {
        this.rightList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    showAddDialog () {
    },
    showEditDialog () {
    },
    deleteRight () {
    }
  }
}
</script>

<style>
</style>
