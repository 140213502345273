<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="roleFormRules" ref="roleFormRef" label-width="80px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="intro">
        <el-input v-model="formData.intro" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'role_add',
  props: [
    'visible', 'id'
  ],
  data () {
    return {
      title: '添加角色',
      formData: {
        name: '',
        avatar: '',
        intro: ''
      },
      roleFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        intro: [
          { required: true, message: '介绍不能为空', trigger: 'blur' },
          { min: 2, max: 500, message: '长度在 2 到 500 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.roleFormRef.resetFields()
      this.$emit('close')
    },
    addConfirm () {
      this.$refs.roleFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑角色'
        this.getInfo(this.id)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/role/info/' + id)
      if (res.code === 0) {
        this.formData = {
          name: res.data.name,
          intro: res.data.intro
        }
      }
    }
  }
}
</script>

<style>
</style>
