<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='图片列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row type="flex" style="display:flex;flex-wrap: wrap;">
        <el-col style="display: inline-block;width: 100px;margin: 5px 5px;">
          <el-button type="primary" @click="showAddDialog">添加图片</el-button>
        </el-col>
        <el-col style="display: inline-block; width: 200px;margin: 5px 5px;">
          <el-select v-model="param.album_id" placeholder="请选择专辑" filterable>
            <el-option label="请选择专辑" :value="0"></el-option>
            <el-option v-for="item in albumList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col style="display: inline-block; width: 300px;margin: 5px 5px;">
          <el-input placeholder="请输入关键字" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <div style="display: inline-block;padding:5px; float:right;">
        <a href="#" v-show="!showContral" @click="changeShowControl" style="display: inline-block;color:#395ce5">排列显示</a>
        <a href="#" v-show="showContral" @click="changeShowControl" style="display: inline-block;color:#395ce5">隐藏</a>
        <div v-show="showContral">
          <span>列数(0为自适应): </span>
          <template>
            <el-input-number v-model="columnCount" :step="1" :min="0" :max="6" size="mini"></el-input-number>
          </template>
          <span style="padding:5px">每页显示个数: </span>
          <template>
            <el-input-number v-model="param.count" :step="10" :min="20" :max="100" size="mini"></el-input-number>
          </template>
          <el-button style="margin-left:8px" type="small" @click="getImageList">确定</el-button>
        </div>
      </div>
    </el-card>
    <image-index-list :imageList="imageList" :columnCount="columnCount"></image-index-list>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]" :page-size="param.count" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import ImageIndexList from './ImageIndexList.vue'

export default {
  name: 'image_list',
  components: {
    'bread-crumb': BreadCrumb,
    'image-index-list': ImageIndexList
  },
  data () {
    return {
      albumList: [],
      imageList: [],
      adminCategoryList: [],
      currentId: 0,
      total: 0,
      pageTotal: 1,
      columnCount: 0,
      showContral: false,
      param: {
        album_id: 0,
        keywords: '',
        admin_category_id: 0,
        page: 1,
        count: 20
      },
      addDialogAvaible: false
    }
  },
  created () {
    this.getAlbumList()
    this.getImageList()
    this.getAdminCategoryList()
  },
  methods: {
    async getAlbumList () {
      const res = await this.$http.get('/admin/album/select_list')
      if (res.code === 0) {
        this.albumList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getAdminCategoryList () {
      const res = await this.$http.get('/admin/admincategory/select_list')
      if (res.code === 0) {
        this.adminCategoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getImageList () {
      const res = await this.$http.get('/admin/image/list', this.param)
      if (res.code === 0) {
        this.imageList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getImageList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getImageList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getImageList()
    },
    showAddDialog () {
      this.$router.push({
        path: '/image/add',
        query: {
          id: 0
        }
      })
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.$router.push({
        path: '/image/add',
        query: {
          id: id
        }
      })
    },
    changeShowControl () {
      this.showContral = !this.showContral
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/image/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getImageList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => { })
    },
    async addImage (data) {
      const res = await this.$http.post('/admin/image/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getImageList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateImage (id, data) {
      const res = await this.$http.post('/admin/image/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getImageList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.list_img {
  width: 50px;
}
</style>
